import React from 'react';
import logo from '../assets/flickscout-logo-png-transparent.png';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

const Header = () => (
    <header className="text-gray-900 py-4 px-6 flex justify-center">
        <Analytics />
        <SpeedInsights />
        <a href="/">
            <img src={logo} alt="Flickscout Logo" className="h-16" />
        </a>
    </header>
);

export default Header;

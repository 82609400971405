import React from 'react';
import { useLocation } from 'react-router-dom';
import SingleMovieBox from '../components/SingleMovieBox';
import { Helmet } from 'react-helmet-async'; 

const MovieDetails = () => {
    const { state } = useLocation();  // Retrieve the passed state (movieData)
    // Check if movieData and results exist
    if (!state || !state.movieData || !state.movieData.results) {
        return <div>No movie data available</div>;
    }

    const movieDataArray = Array.isArray(state.movieData.results) 
        ? state.movieData.results 
        : [state.movieData.results];  // Ensure movieData is in an array form

    return (
        <>
            {/* Add dynamic meta tags using Helmet */}
            <Helmet>
                <title>List of AI-recommended movies</title>
                <meta name="description" content="Here the list of movies according to your description" />
            </Helmet>

            <div className="bg-white text-black min-h-screen p-6">
                <h1 className="text-2xl font-bold mb-4">List of movies based on your description:</h1>
                <div className="space-y-4">
                    {/* Loop through movieDataArray and render SingleMovieBox for each movie */}
                    {movieDataArray.map((movieData, index) => (
                        <SingleMovieBox key={index} movieData={movieData.movie_data} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default MovieDetails;
import React, { useState } from 'react';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null); // Store the token once obtained

    const apiUrl = process.env.REACT_APP_API_URL;
    // Handle the registration and token retrieval process
    const handleRegister = async () => {
        setLoading(true);
        try {
            // Step 1: Register the user
            const registerResponse = await fetch(`${apiUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    email,
                }),
            });

            if (!registerResponse.ok) {
                throw new Error(`Error registering user: ${registerResponse.statusText}`);
            }

            const registerData = await registerResponse.json();
            console.log('User registered:', registerData.message);

            // Step 2: After successful registration, login to get the token
            const loginResponse = await fetch(`${apiUrl}/token`, {
                method: 'POST',
                body: new URLSearchParams({
                    username,
                    password,
                }),
            });

            if (!loginResponse.ok) {
                throw new Error(`Error logging in: ${loginResponse.statusText}`);
            }

            const loginData = await loginResponse.json();
            const accessToken = loginData.access_token;
            console.log('Received token:', accessToken);

            // Store the token
            setToken(accessToken);
        } catch (error) {
            console.error('Error during registration or token fetch:', error);
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Register</h1>
            <div>
                <label>Username:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div>
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button onClick={handleRegister} disabled={loading}>
                {loading ? 'Registering...' : 'Register'}
            </button>
            {token && (
                <div>
                    <h2>Token Received!</h2>
                    <p>{token}</p>
                </div>
            )}
        </div>
    );
};

export default Register;

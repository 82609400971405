import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import Header from './components/Header';
import HomePage from './pages/HomePage';
import MovieDetails from './pages/MovieDetails';
import Register from './components/Register';

const App = () => (
  <HelmetProvider>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/MovieDetails" element={<MovieDetails />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  </HelmetProvider>
);

export default App;
import React, { useState } from 'react';
import micIcon from '../assets/mic-icon.png';
import loadingGif from '../assets/loading.gif';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useAudioRecorder from '../hooks/useAudioRecorder';
import { useNavigate } from 'react-router-dom';  
import { Helmet } from 'react-helmet-async'; 

const HomePage = () => {
    const [visibleBox, setVisibleBox] = useState(null);
    const [isUploading, setIsUploading] = useState(false);  // Track upload status
    const {
        isRecording,
        startRecording,
        stopRecording,
        permissionError,
    } = useAudioRecorder();
    const navigate = useNavigate();  // For navigation (updated)

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleRecord = async () => {
        // Update the button state immediately
        if (!isRecording) {
            startRecording(); // No need to await, start recording immediately
        } else {
            try {
                const audioBlob = await stopRecording();
                if (audioBlob) {
                    uploadAudio(audioBlob);
                } else {
                    console.error('No audio data available.');
                }
            } catch (error) {
                console.error('Error stopping recording:', error);
            }
        }
    
        // Perform the warmup API call asynchronously in the background
        fetch(`${apiUrl}/warmup/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`, // Authorization header for the API
            },
        })
            .then(() => {
                console.log('Warmup endpoint called successfully.');
            })
            .catch((error) => {
                console.error('Failed to call the warmup endpoint:', error);
            });
    };

    const uploadAudio = (audioBlob) => {
        
        const token = process.env.REACT_APP_API_TOKEN
    
        setIsUploading(true);  // Set uploading state to true
    
        // Prepare the FormData with the actual audio file
        const formDataForMovies = new FormData();
        formDataForMovies.append('file', audioBlob, 'description.mp3');  // Attach the actual audio file
    
        // Send the audio file directly to the movies endpoint
        fetch(`${apiUrl}/movies/`, {
            method: 'POST',
            headers: {
            'Authorization': `Bearer ${token}`,  // Authorization header for the API
            },
            body: formDataForMovies  // Send the audio file itself as FormData
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Failed to fetch movies: ${response.statusText}`);
            }
            return response.json();
        })
        .then((movieData) => {
            setIsUploading(false);  // Set uploading state to false
            navigate('/MovieDetails', { state: { movieData } });  // Pass the movie data to MovieDetails page
        })
        .catch((error) => {
            console.error('Failed to fetch movies:', error);
            setIsUploading(false);  // Set uploading state to false
        });
    };
    

    const toggleBox = (box) => {
        setVisibleBox((prev) => (prev === box ? null : box));
    };

    return (
        <>
            <Helmet>
                <title>FlickScout.com - Describe a movie, watch it.</title>
                <meta
                name="description"
                content="Describe the movie you want to watch and FlickScout will instantly recommend the perfect match tailored to your description!"
                />
            </Helmet>
            <div className=" text-gray-800">
                <div className="container mx-auto py-10 px-4">
                    <h1 className="font-bold text-center">
                        AI-powered movie
                        <br />
                        recommendations.
                    </h1>
                    <h2 className="text-center mb-8">
                        Stop wasting your life browsing Netflix.
                        <br />
                        Get a movie recommendation for exactly what you want to watch.
                    </h2>
                    <div className="flex flex-col items-center">
                        {/* Conditionally render the button or the loading GIF */}
                        {!isUploading ? (
                            <button
                                onClick={handleRecord}
                                className={`shadow-lg p-4 rounded-full hover:bg-gray-800 transition flex justify-center items-center ${isRecording ? 'bg-red-500' : 'bg-black'} sm:w-full md:w-auto`}
                            >
                                <img
                                    src={micIcon}
                                    alt="Record Audio"
                                    className={`w-12 h-12 invert ${isRecording ? 'animate-pulse' : ''}`}
                                />
                            </button>
                        ) : (
                            <div className="flex justify-center items-center">
                                <img src={loadingGif} alt="Loading..." className="w-14 h-14" />
                            </div>
                        )}

                        <p className="text-center text-sm mt-2">
                            {isRecording
                                ? 'Recording... Click to stop.'
                                : isUploading
                                ? 'Generating your personalized recommendations...'
                                : 'Press to record audio'}
                        </p>
                        {permissionError && (
                            <p className="text-red-500 mt-2">{permissionError}</p>
                        )}
                        <div className="flex mt-12 space-x-4">
                            <button
                                className="button-left flex items-center"
                                onClick={() => toggleBox('quickGuide')}
                            >
                                Quick Guide
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                            </button>
                            <button
                                className="button-right flex items-center"
                                onClick={() => toggleBox('inspireMe')}
                            >
                                Inspire Me
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Quick Guide Box  */}
            {visibleBox === 'quickGuide' && (
                <div
                className="fixed bottom-0 left-0 w-full quick-guide bg-black text-white p-6 overflow-y-auto"
                style={{ height: '40vh' }}
            >
                    <div className="content">
                        <p className="font-bold text-lg mb-4 text-center">Quick Guide</p>
                        <div className="text-sm space-y-2 text-black">
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                The more specific you are - the better the recommendations.
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                Think genre, era, cast, style, director, budget, language,
                                rating... let your imagination run free.
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                Speak naturally in your own language for the best results.
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={() => setVisibleBox(null)}
                                className="mt-4 bg-white text-black px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Inspire Me Box */}
            {visibleBox === 'inspireMe' && (
              <div
              className="fixed bottom-0 left-0 w-full bg-black  inspire-me p-6 overflow-y-auto"
              style={{ height: '40vh' }}
          >
                    <div className="content">
                        <p className="font-bold text-lg mb-4 text-center">Inspire Me</p>
                        <div className="text-sm space-y-2">
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                Action movie before 1980 starring a woman.
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                Low budget horror comedy set in the woods.
                            </div>
                            <div className="bg-white p-2 rounded-md shadow-sm">
                                Highly-rated Korean crime thriller from the 90s.
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={() => setVisibleBox(null)}
                                className="mt-4 bg-white text-black px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HomePage;

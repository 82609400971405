import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartBroken } from '@fortawesome/free-solid-svg-icons';

// Base URLs
const IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/w500';
const YOUTUBE_BASE_URL = 'https://www.youtube.com/embed/';

const SingleMovieBox = ({ movieData }) => {
    const [isLiked, setIsLiked] = useState(false); // State to track whether the movie is liked
    const apiUrl = process.env.REACT_APP_API_URL;

    console.log(movieData); // Useful for debugging

    const handleLike = async () => {
        try {
            // Only trigger the API call when the heart is broken (movie is being liked)
            if (!isLiked) {
                // API call to score the movie
                const response = await fetch(`${apiUrl}/score_movie`, { // Corrected with backticks
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ movie_id: movieData.id }),
                });

                // Check for response errors
                if (!response.ok) {
                    throw new Error('Failed to score the movie');
                }
            }

            // Toggle the like state locally
            setIsLiked((prev) => !prev);
        } catch (error) {
            console.error('Error during movie scoring:', error);
            // Optionally, revert the local like state if the API call fails
            setIsLiked((prev) => !prev);
        }
    };

    return (
        <div className="flex flex-col md:flex-row w-full md:w-[70%] bg-white p-4 rounded-lg shadow-md mx-auto">
            {/* Left: Movie Image */}
            <div className="w-full md:w-1/5 flex-shrink-0 mb-4 md:mb-0 flex justify-center">
                <img
                    src={`${IMAGE_BASE_URL}${movieData.poster_path}`} // Corrected with backticks
                    alt={movieData.title}
                    className="w-full h-auto object-cover rounded-lg max-w-xs"
                />
            </div>

            {/* Center: Movie Details */}
            <div className="w-full md:w-3/5 mx-0 md:mx-4 flex flex-col justify-start items-start">
                <h2 className="text-xl md:text-2xl font-semibold text-left">
                    {movieData.title}
                    <span className="text-sm text-gray-500 pl-1">
                        ({new Date(movieData.release_date).getFullYear()})
                    </span>
                </h2>
                {movieData.tagline && (
                    <h3 className="text-md md:text-lg text-gray-600 mt-1 text-left">
                        {movieData.tagline}
                    </h3>
                )}
                <div className="mt-2 text-left">
                    <div className="flex flex-wrap space-x-2 mt-1">
                        {movieData.genres &&
                            movieData.genres.map((genre) => (
                                <span
                                    key={genre.id}
                                    className="text-xs bg-gray-200 text-gray-800 px-2 py-1 rounded-full mb-1"
                                >
                                    {genre.name}
                                </span>
                            ))}
                    </div>
                </div>
                <p className="text-sm mt-2 text-gray-700 text-left">{movieData.overview}</p>

                {/* Cast & Crew */}
                {movieData.credits && movieData.credits.cast && movieData.credits.cast.length > 0 && (
                    <div className="mt-4 w-full">
                        <h3 className="text-lg font-semibold">Top Cast</h3>
                        <div className="flex flex-wrap mt-2">
                            {movieData.credits.cast.slice(0, 5).map((actor) => (
                                <div key={actor.cast_id} className="mr-4 mb-4 w-24">
                                    <div className="w-20 h-20 overflow-hidden rounded-full mb-2">
                                        <img
                                            src={
                                                actor.profile_path
                                                    ? `${IMAGE_BASE_URL}${actor.profile_path}` // Corrected with backticks
                                                    : '/placeholder-profile.png' // Placeholder image
                                            }
                                            alt={actor.name}
                                            className="w-full h-full object-cover"
                                            loading="lazy" // Optional: Lazy loading for performance
                                        />
                                    </div>
                                    <p className="text-sm font-medium">{actor.name}</p>
                                    <p className="text-xs text-gray-600">{actor.character}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {movieData.credits && movieData.credits.crew && movieData.credits.crew.length > 0 && (
                    <div className="mt-4 w-full">
                        <h3 className="text-lg font-semibold">Key Crew</h3>
                        <div className="flex flex-wrap mt-2">
                            {movieData.credits.crew
                                .filter((member, index, self) =>
                                    index === self.findIndex((m) => m.name === member.name && m.job === member.job)
                                )
                                .slice(0, 5)
                                .map((member) => (
                                    <div key={member.credit_id} className="w-1/2 mb-2">
                                        <span className="font-medium">{member.name}:</span> {member.job}
                                    </div>
                                ))}
                        </div>
                    </div>
                )}

                {/* Additional Images */}
                {movieData.images && movieData.images.backdrops && movieData.images.backdrops.length > 0 && (
                    <div className="mt-4 w-full">
                        <h3 className="text-lg font-semibold">Additional Images</h3>
                        <div className="flex overflow-x-scroll space-x-2 mt-2">
                            {movieData.images.backdrops.slice(0, 5).map((img, index) => (
                                <img
                                    key={index}
                                    src={`${IMAGE_BASE_URL}${img.file_path}`} // Corrected with backticks
                                    alt="Movie Backdrop"
                                    className="rounded-md h-32 object-cover"
                                    loading="lazy" // Optional: Lazy loading for performance
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Trailer/Video Preview */}
                {movieData.videos && movieData.videos.results && movieData.videos.results.length > 0 && (
                    <div className="mt-4 w-full md:w-2/3">
                        <h3 className="text-lg font-semibold">Trailer</h3>
                        {movieData.videos.results
                            .filter((video) => video.site === 'YouTube' && video.type === 'Trailer')
                            .slice(0, 1) // Show only the first trailer
                            .map((video) => (
                                <div className="aspect-w-16 aspect-h-9 mt-2" key={video.id}>
                                    <iframe
                                        src={`${YOUTUBE_BASE_URL}${video.key}`} // Corrected with backticks
                                        title={video.name}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="w-full h-full"
                                    ></iframe>
                                </div>
                            ))}
                    </div>
                )}

                {/* Watch Providers */}
                {movieData.watch_providers && movieData.watch_providers.results && movieData.watch_providers.results.US && (
                    <div className="mt-4 w-full">
                        <h3 className="text-lg font-semibold">Where to Watch</h3>
                        <div className="mt-2 text-sm text-gray-700">
                            {movieData.watch_providers.results.US.flatrate && (
                                <div className="mb-2">
                                    <strong>Subscription:</strong>
                                    <div className="flex space-x-2 mt-1 items-center">
                                        {movieData.watch_providers.results.US.flatrate.map((prov) => (
                                            <div key={prov.provider_id} className="flex items-center">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w45${prov.logo_path}`} // Corrected with backticks
                                                    alt={prov.provider_name}
                                                    className="inline-block mr-1 h-6"
                                                />
                                                <span>{prov.provider_name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {movieData.watch_providers.results.US.buy && (
                                <div className="mb-2">
                                    <strong>Buy:</strong>
                                    <div className="flex space-x-2 mt-1 items-center">
                                        {movieData.watch_providers.results.US.buy.map((prov) => (
                                            <div key={prov.provider_id} className="flex items-center">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w45${prov.logo_path}`} // Corrected with backticks
                                                    alt={prov.provider_name}
                                                    className="inline-block mr-1 h-6"
                                                />
                                                <span>{prov.provider_name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {movieData.watch_providers.results.US.rent && (
                                <div className="mb-2">
                                    <strong>Rent:</strong>
                                    <div className="flex space-x-2 mt-1 items-center">
                                        {movieData.watch_providers.results.US.rent.map((prov) => (
                                            <div key={prov.provider_id} className="flex items-center">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w45${prov.logo_path}`} // Corrected with backticks
                                                    alt={prov.provider_name}
                                                    className="inline-block mr-1 h-6"
                                                />
                                                <span>{prov.provider_name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Right: Heart Icon and Movie Score */}
            <div className="w-full md:w-1/5 flex md:flex-col justify-center md:justify-start items-center mt-4 md:mt-0 space-x-4 md:space-x-0 md:space-y-4">
                <div className="cursor-pointer" onClick={handleLike}>
                    <FontAwesomeIcon
                        icon={isLiked ? faHeart : faHeartBroken}
                        className={`text-3xl ${isLiked ? 'text-red-500' : 'text-gray-500'}`} // Corrected with backticks
                    />
                </div>
                <p className="text-lg md:text-xl font-semibold">
                    {movieData.vote_average.toFixed(1)} / 10{' '}
                    <span className="text-sm text-gray-600 pl-2">({movieData.vote_count} votes)</span>
                </p>
            </div>
        </div>
    );

};

export default SingleMovieBox;
